import $ from 'jquery';

window.addEventListener("DOMContentLoaded", (evt) => {
    if(localStorage.getItem('cookiePreferences') === null) {
        localStorage.setItem('cookiePreferences', '');
    }

    const cookieAlert = $('#cookieAlert').alert();
    const cookieModal = $('#cookieModal');
    const checkBoxes = document.getElementsByName("cookiePreference");
    const saveButtons = document.querySelectorAll('.save-cookies');

    function setCheckboxFromLocalstorage() {
        if (localStorage.getItem("cookieConsent") && localStorage.getItem("cookiePreferences")) {
            const storedValues = localStorage.getItem("cookiePreferences").split(",");
            checkBoxes.forEach((el) => {
                if (storedValues.includes(el.value)) {
                    el.checked = true;
                }
            });
        } else {
            // set default values true for cookie consent
            checkBoxes.forEach((el) => {
                el.checked = true;
            });
        }
    }

    function getCookiePreferences() {
        const selectedCboxes = Array.prototype.slice.call(checkBoxes).filter((cb) => cb.checked == true);
        const selectedValues = [];
        selectedCboxes.forEach((e) => {
            selectedValues.push(e.value);
        });
        return selectedValues;
    }

    function activateScripts(buttonScope = false) {
        const storedValues = localStorage.getItem("cookiePreferences").split(",");
        storedValues.forEach((category) => {
            const cookieScripts = document.querySelectorAll(`[data-cookietype="${ category }"]`);
            cookieScripts.forEach((script) => {
                if (script.dataset.type !== script.type) {
                    const newNode = document.createElement(script.tagName);
                    newNode.innerHTML = script.innerHTML;
                    for (let i = 0, max = script.attributes.length - 1; i <= max; ++i) {
                        newNode.setAttribute(script.attributes[i].name,script.attributes[i].value);
                    }
                    newNode.setAttribute('type', script.dataset.type);
                    script.parentNode.insertBefore(newNode, script);
                    script.parentNode.removeChild(script);
                }
            });
        });
        if (buttonScope) {
            document.querySelector('#cookieAlert').classList.add('d-none');
            cookieModal.modal('hide');
        }
    }

    function saveCookies() {
        localStorage.setItem("cookieConsent", true);
        localStorage.setItem("cookiePreferences", getCookiePreferences());

        activateScripts(true);
    }


    setCheckboxFromLocalstorage();
    activateScripts();

    saveButtons.forEach(el => { el.addEventListener('click', () => { saveCookies() }) });

    if (localStorage.getItem("cookieConsent") === null) {
        document.getElementById("cookieAlert").classList.remove("d-none");
    }

});
