import $ from 'jquery';
//import ionRangeSlider from 'ion-rangeslider';
//import ionRangeSliderCSS from 'ion-rangeslider/css/ion.rangeSlider.min.css';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
function convertPixelsToRem(pixel) {
    return pixel / parseFloat(getComputedStyle(document.documentElement).fontSize);
}
/**
 * If possible, rounds to proper pixels in rem
 */
function roundRem(rem) {
    let unroundedPixel = convertRemToPixels(rem);
    let roundedPixel = Math.round(unroundedPixel);
    let roundedRem = convertPixelsToRem(roundedPixel);

    // When rem becomes e.g. -5.722222222222222
    let alwaysRoundedRem = Math.round(roundedRem * 1000) / 1000;

    return alwaysRoundedRem;
}

$(document).ready(function() {
    $('.removeLineItemButton').click(function(event) {
        var id = $(this).data('line-item-id');
        $('.checkbox-' + id).prop('checked', true);
        $( "#formcart" ).submit();
    });

    $('#billingAddressSameAsShipping').click(function(event) {
        var billingAddressSameAsShippingStatus = $(this).prop('checked');
        if (billingAddressSameAsShippingStatus == true) {
            $( "#billingAddressSameAsShippingText" ).val(1);
        }
        else {
            $( "#billingAddressSameAsShippingText" ).val(0);
        }
    });

    // billing Address Same As Shipping Address
    $( "#address-form" ).submit(function( event ) {
        var billingAddressSameAsShippingStatus = $('#billingAddressSameAsShipping').prop('checked');
        if (billingAddressSameAsShippingStatus == true) {
            var addressArr = [
                'attention',
                'firstName',
                'lastName',
                'businessName',
                'address1',
                'postalCode',
                'city',
                'phone',
                'countryId'
            ];
            for (let i=0; i<addressArr.length; i++ ) {
                $( "#billingAddress-" + addressArr[i] ).val($( "#shippingAddress-" + addressArr[i] ).val());
            }
        }
    });

    //smooth scroll top
    $(".to-top").on('click', function() {
        $('html, body').animate({scrollTop:0},'150');
    });
});


document.addEventListener('DOMContentLoaded', (event) => {

    // Load CSRF cookie
    let csrfCall = $.get("/api/ajax-getcsrf", function (data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
        window.forms = data.forms;
    }, "json");

    $.when(csrfCall).then(function(data) {
        $(".form-component form").each(function() {
            let formHandleHtml = $(this).parent().data('form');
            let $form = $(this);

            window.forms.forEach(function(windowData) {
                if(formHandleHtml == windowData.formHandle) {
                    $form.find('input[name="formHash"]').val(windowData.formHash); // + ' ajax added hash'
                }
            });
            $(this).find('input[name="CRAFT_CSRF_TOKEN"]').val(window.csrfTokenValue); // + ' ajax added csrf'
        })
    });

    // Prevent elements from flickering on page load
    document.querySelectorAll('[data-cloak]').forEach(function(element) {
        element.removeAttribute('data-cloak');
    });


    // Desktop Navigation
    const websiteHeader = document.querySelector('.website-header');
    const navigationDropdown = document.querySelector('.main-navigation-dropdown');
    let navState = 'close';

    $(document).on('click', '.main-navigation > .main-navigation-link', function(e) {
        e.preventDefault();
        const navSection = $(this).data('navsection');
        const $mainNavDropdown = $(".main-navigation-dropdown");        
        const $mainNavDropdownToShow = $('.main-navigation-dropdown[data-navdropdown="' + navSection + '"]');
        
        $mainNavDropdown.removeClass('open');
        if($mainNavDropdown.hasClass(':not(.open)')) {
            $mainNavDropdownToShow.addClass('open');
            $(".search-block").removeClass('open');
            $('body').addClass('position-fixed w-100');
        } else {
            $mainNavDropdownToShow.addClass('open');
            $(".search-block").removeClass('open');
            $('body').addClass('position-fixed w-100');
        }
    });

    $(document).on('click', '.close-navigation', function(e) {
        $(".main-navigation-dropdown").removeClass('open');
        $('body').removeClass('position-fixed w-100');
    });

    // Mobile Navigation
    $(document).on('click', '.mobile-nav-toggler', function(e) {
        $(this).toggleClass('open');
        const $mobileNav = $('.mobile-main-navigation');
        const $body = $('body');

        if ($mobileNav.hasClass('open')) {
            $mobileNav.removeClass('open');
            $body.removeClass('position-fixed w-100');
        } else {
            $mobileNav.addClass('open');
            $(".search-block").removeClass('open');
            $body.addClass('position-fixed w-100');
        }
    });

    $(document).on('click', '.sub-block-opener', function(e) {
        $(this).toggleClass('open');
        $(this).parent().next('.sub-block').toggleClass('open');

        if($('.mobile-main-navigation').hasClass('open')) {
            $('body').addClass('position-fixed w-100');
        }
    });

    $(document).on('click', '.filter-wrapper-opener, .filter-block h4', function(e) {
        $(this).stop().toggleClass('open');
        $(this).parent().next().stop().slideToggle();
    });

    //search button onclick show search box
    $('.search-button').on('click', function() {
        $(".main-navigation-dropdown, .mobile-nav-toggler, .mobile-main-navigation").removeClass('open');
        $('body').toggleClass('position-fixed w-100');
        $(".search-block").toggleClass('open');
        $(".search-input").focus();
    });

    $(".close-search").on('click', function() {
        $(".search-block").removeClass('open');
        $('body').removeClass('position-fixed w-100');
    });

    //set noopener noreferrer attribute to external links
    $('a[href^="http"], a[href^="https"]').not('a[href*="' + location.hostname + '"]').attr({ rel: "noopener noreferrer" });
    
    
    /* define variables and function */
    let $nlElement = $('.newsletter-signup');
    let $footerElement = $('footer.page-footer .container');

    /**
     * To switch function to keep existing styles use:
     // $elem.attr('style', function(i,s) { return (s || '') + 'padding-top: ' + 0 + 'rem !important;' });
     */
    function calcSectionPadding() {
        let elemHalfHeight = convertPixelsToRem($nlElement.outerHeight() / 2);
        let additionalSpace = 3; // rem
        $footerElement.attr('style', 'padding-top: ' + roundRem(elemHalfHeight + additionalSpace) + 'rem !important;');
        $nlElement.attr('style', 'margin-top: ' + roundRem(0 - elemHalfHeight + additionalSpace) + 'rem !important;');
    }

    /* init */
    calcSectionPadding();

    /* add padding to last child element before footer (to fix newsletter translateY(-50%)) */
    $(window).on('resize', function(e) {
        calcSectionPadding();
    });

    flatpickr("#eventStartdateFilter", {
        locale: "de",
        dateFormat: "d.m.Y",
        defaultDate: new Date(),
        maxDate: "01.01.2053",
        minDate: "01.01.2023",
        disableMobile: "true"
    });
    flatpickr("#eventEnddateFilter", {
        locale: "de",
        dateFormat: "d.m.Y",
        maxDate: "01.01.2053",
        minDate: "01.01.2022",
        disableMobile: "true"
    });
    
    $("body").on("click", ".remove-datefilter", function() {
        $(this).prev('input').val('');
        document.getElementById('eventFilterComponent').dispatchEvent(new Event('refresh'));
    });

    $("body").on("click", ".remove-all-filters", function() {
        $("#eventEnddateFilter, #eventStartdateFilter, #eventSearchInput").val('');
        $(".tag-filter").prop("checked", false);
        document.getElementById('eventFilterComponent').dispatchEvent(new Event('refresh'));
    });

    // Slider component
    const sliderIcon = 'M 0 50 L 28 22 l 7 7 L 19 45 L 100 45 L 100 55 L 19 55 L 35 71 l -7 7 Z';

    const sliders = document.querySelectorAll('.slider.type-1');
    sliders.forEach((component) => {
        const mainSlider = component.querySelector('.slider-main');
        const descSlider = component.querySelector('.slider-description');

        new Flickity(mainSlider, {
            arrowShape: sliderIcon,
            wrapAround: true,
            imagesLoaded: true,
            cellAlign: 'left',
        });

        // Descriptions are optional
        if (descSlider) {
            new Flickity(descSlider, {
                wrapAround: true,
                prevNextButtons: false,
                draggable: false,
                pageDots: false,
                adaptiveHeight: true,
                cellAlign: 'left',
                asNavFor: mainSlider,
            });
        }
    })
    const sliders2 = document.querySelectorAll('.slider.type-2');
    sliders2.forEach((component) => {
        new Flickity(component.querySelector('.slider'), {
            arrowShape: sliderIcon,
            wrapAround: true,
            imagesLoaded: true,
            cellAlign: 'left',
        });
    })
    const slidersPOIDetailsHeader = document.querySelectorAll('.slider.type-3');
    slidersPOIDetailsHeader.forEach((component) => {
        new Flickity(component.querySelector('.slider'), {
            arrowShape: sliderIcon,
            wrapAround: true,
            imagesLoaded: true,
            cellAlign: 'left',
        });
    })
    const slidersPOIDetails = document.querySelectorAll('.slider.type-4');
    slidersPOIDetails.forEach((component) => {
        new Flickity(component.querySelector('.slider'), {
            arrowShape: sliderIcon,
            wrapAround: true,
            imagesLoaded: true,
            pageDots: false,
            cellAlign: 'left',
        });
    })

    // Gallery component
    if (document.querySelector('.gallery') !== null) {
        lightbox.option({
            disableScrolling: true,
            fadeDuration: 300,
            resizeDuration: 400,
            wrapAround: true,
        })
    }
    const galleries = document.querySelectorAll('.gallery.type-1');
    galleries.forEach((component) => {
        const mainSlider = component.querySelector('.slider-main');
        const navSlider = component.querySelector('.slider-nav');

        new Flickity(mainSlider, {
            draggable: false,
            freeScroll: true,
            prevNextButtons: false,
            pageDots: false,
            adaptiveHeight: true,
            imagesLoaded: true,
            cellSelector: '.slider-item',
        });

        new Flickity(navSlider, {
            asNavFor: mainSlider,
            cellAlign: 'left',
            freeScroll: true,
            pageDots: false,
            contain: true,
            setGallerySize: false,
            adaptiveHeight: true,
            arrowShape: sliderIcon,
        });
    });
    const galleries2 = document.querySelectorAll('.gallery.type-2');
    galleries2.forEach((component) => {
        new Flickity(component.querySelector('.slider'), {
            pageDots: false,
            wrapAround: true,
            imagesLoaded: true,
            cellSelector: '.slider-item',
            arrowShape: sliderIcon,
        });
    });

    // Related Shop Articles Component
    const relatedShopArticles = document.querySelectorAll('.related-shop-articles');
    relatedShopArticles.forEach((component) => {
        const slider = new Flickity(component.querySelector('.articles .slider'), {
            draggable: true,
            imagesLoaded: true,
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            cellAlign: 'left',
        });

        component.querySelector('.slider-previous').addEventListener('click', (event) => {
            slider.previous();
        })
        component.querySelector('.slider-next').addEventListener('click', (event) => {
            slider.next();
        })
    });
});

